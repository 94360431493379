let coaches = [
    {
        name: 'Demonologist',
        rating: 5,
        image: './jpg/1.jpg',
        title: 'Teaching ADC League of Legends',
        game: 'League of Legends',
        level: 'Intermediate',
        price: 35,
        labelText:"Rising",
        labelColor:"#EDAE00",
    },
    {
        name: 'Demonologist',
        rating: 3,
        image: './jpg/2.jpg',
        title: 'Teaching ADC League of Legends',
        game: 'League of Legends',
        level: 'Advanced',
        price: 35,
        labelText: "New",
        labelColor: "#fff",
    },
    {
        name: 'Demonologist',
        rating: 4,
        image: './jpg/3.jpg',
        title: 'Teaching ADC League of Legends',
        game: 'League of Legends',
        level: 'Beginner',
        price: 35,
        labelText:"50%",
        labelColor:"#ED0033",
    },
    {
        name: 'Demonologist',
        rating: 5,
        image: './jpg/4.jpg',
        title: 'Teaching ADC League of Legends',
        game: 'League of Legends',
        level: 'Advanced',
        price: 35,
    },
    {
        name: 'Demonologist',
        rating: 4,
        image: './jpg/5.jpg',
        title: 'Teaching ADC League of Legends',
        game: 'League of Legends',
        level: 'Intermediate',
        price: 35,
    },
    {
        name: 'Demonologist',
        rating: 4,
        image: './jpg/6.jpg',
        title: 'Teaching ADC League of Legends',
        game: 'League of Legends',
        level: 'Advanced',
        price: 35,
    },
    {
        name: 'Demonologist',
        rating: 5,
        image: './jpg/1.jpg',
        title: 'Teaching ADC League of Legends',
        game: 'League of Legends',
        level: 'Intermediate',
        price: 35,
    },
    {
        name: 'Demonologist',
        rating: 3,
        image: './jpg/2.jpg',
        title: 'Teaching ADC League of Legends',
        game: 'League of Legends',
        level: 'Advanced',
        price: 35,
    },
    {
        name: 'Demonologist',
        rating: 4,
        image: './jpg/3.jpg',
        title: 'Teaching ADC League of Legends',
        game: 'League of Legends',
        level: 'Beginner',
        price: 35,
    },
    {
        name: 'Demonologist',
        rating: 5,
        image: './jpg/4.jpg',
        title: 'Teaching ADC League of Legends',
        game: 'League of Legends',
        level: 'Advanced',
        price: 35,
    },
    {
        name: 'Demonologist',
        rating: 4,
        image: './jpg/5.jpg',
        title: 'Teaching ADC League of Legends',
        game: 'League of Legends',
        level: 'Intermediate',
        price: 35,
    },
    {
        name: 'Demonologist',
        rating: 4,
        image: './jpg/6.jpg',
        title: 'Teaching ADC League of Legends',
        game: 'League of Legends',
        level: 'Advanced',
        price: 35,
    },
    {
        name: 'Demonologist',
        rating: 4,
        image: './jpg/3.jpg',
        title: 'Teaching ADC League of Legends',
        game: 'League of Legends',
        level: 'Beginner',
        price: 35,
    },
    {
        name: 'Demonologist',
        rating: 5,
        image: './jpg/4.jpg',
        title: 'Teaching ADC League of Legends',
        game: 'League of Legends',
        level: 'Advanced',
        price: 35,
    },
    {
        name: 'Demonologist',
        rating: 4,
        image: './jpg/5.jpg',
        title: 'Teaching ADC League of Legends',
        game: 'League of Legends',
        level: 'Intermediate',
        price: 35,
    },
    {
        name: 'Demonologist',
        rating: 4,
        image: './jpg/6.jpg',
        title: 'Teaching ADC League of Legends',
        game: 'League of Legends',
        level: 'Advanced',
        price: 35,
    },
    {
        name: 'Demonologist',
        rating: 4,
        image: './jpg/3.jpg',
        title: 'Teaching ADC League of Legends',
        game: 'League of Legends',
        level: 'Beginner',
        price: 35,
    },
    {
        name: 'Demonologist',
        rating: 5,
        image: './jpg/4.jpg',
        title: 'Teaching ADC League of Legends',
        game: 'League of Legends',
        level: 'Advanced',
        price: 35,
    },
    {
        name: 'Demonologist',
        rating: 4,
        image: './jpg/5.jpg',
        title: 'Teaching ADC League of Legends',
        game: 'League of Legends',
        level: 'Intermediate',
        price: 35,
    },
    {
        name: 'Demonologist',
        rating: 4,
        image: './jpg/6.jpg',
        title: 'Teaching ADC League of Legends',
        game: 'League of Legends',
        level: 'Advanced',
        price: 35,
    },
    {
        name: 'Demonologist',
        rating: 4,
        image: './jpg/3.jpg',
        title: 'Teaching ADC League of Legends',
        game: 'League of Legends',
        level: 'Beginner',
        price: 35,
    },
    {
        name: 'Demonologist',
        rating: 5,
        image: './jpg/4.jpg',
        title: 'Teaching ADC League of Legends',
        game: 'League of Legends',
        level: 'Advanced',
        price: 35,
    },
    {
        name: 'Demonologist',
        rating: 4,
        image: './jpg/5.jpg',
        title: 'Teaching ADC League of Legends',
        game: 'League of Legends',
        level: 'Intermediate',
        price: 35,
    },
    {
        name: 'Demonologist',
        rating: 4,
        image: './jpg/6.jpg',
        title: 'Teaching ADC League of Legends',
        game: 'League of Legends',
        level: 'Advanced',
        price: 35,
    },
    {
        name: 'Demonologist',
        rating: 4,
        image: './jpg/3.jpg',
        title: 'Teaching ADC League of Legends',
        game: 'League of Legends',
        level: 'Beginner',
        price: 35,
    },
    {
        name: 'Demonologist',
        rating: 5,
        image: './jpg/4.jpg',
        title: 'Teaching ADC League of Legends',
        game: 'League of Legends',
        level: 'Advanced',
        price: 35,
    },
    {
        name: 'Demonologist',
        rating: 4,
        image: './jpg/5.jpg',
        title: 'Teaching ADC League of Legends',
        game: 'League of Legends',
        level: 'Intermediate',
        price: 35,
    },
    {
        name: 'Demonologist',
        rating: 4,
        image: './jpg/6.jpg',
        title: 'Teaching ADC League of Legends',
        game: 'League of Legends',
        level: 'Advanced',
        price: 35,
    },
    {
        name: 'Demonologist',
        rating: 4,
        image: './jpg/3.jpg',
        title: 'Teaching ADC League of Legends',
        game: 'League of Legends',
        level: 'Beginner',
        price: 35,
    },
    {
        name: 'Demonologist',
        rating: 5,
        image: './jpg/4.jpg',
        title: 'Teaching ADC League of Legends',
        game: 'League of Legends',
        level: 'Advanced',
        price: 35,
    },
    {
        name: 'Demonologist',
        rating: 4,
        image: './jpg/5.jpg',
        title: 'Teaching ADC League of Legends',
        game: 'League of Legends',
        level: 'Intermediate',
        price: 35,
    },
    {
        name: 'Demonologist',
        rating: 4,
        image: './jpg/6.jpg',
        title: 'Teaching ADC League of Legends',
        game: 'League of Legends',
        level: 'Advanced',
        price: 35,
    },
    {
        name: 'Demonologist',
        rating: 4,
        image: './jpg/3.jpg',
        title: 'Teaching ADC League of Legends',
        game: 'League of Legends',
        level: 'Beginner',
        price: 35,
    },
    {
        name: 'Demonologist',
        rating: 5,
        image: './jpg/4.jpg',
        title: 'Teaching ADC League of Legends',
        game: 'League of Legends',
        level: 'Advanced',
        price: 35,
    },
    {
        name: 'Demonologist',
        rating: 4,
        image: './jpg/5.jpg',
        title: 'Teaching ADC League of Legends',
        game: 'League of Legends',
        level: 'Intermediate',
        price: 35,
    },
    {
        name: 'Demonologist',
        rating: 4,
        image: './jpg/6.jpg',
        title: 'Teaching ADC League of Legends',
        game: 'League of Legends',
        level: 'Advanced',
        price: 35,
    },
    {
        name: 'Demonologist',
        rating: 4,
        image: './jpg/3.jpg',
        title: 'Teaching ADC League of Legends',
        game: 'League of Legends',
        level: 'Beginner',
        price: 35,
    },
    {
        name: 'Demonologist',
        rating: 5,
        image: './jpg/4.jpg',
        title: 'Teaching ADC League of Legends',
        game: 'League of Legends',
        level: 'Advanced',
        price: 35,
    },
    {
        name: 'Demonologist',
        rating: 4,
        image: './jpg/5.jpg',
        title: 'Teaching ADC League of Legends',
        game: 'League of Legends',
        level: 'Intermediate',
        price: 35,
    },
    {
        name: 'Demonologist',
        rating: 4,
        image: './jpg/6.jpg',
        title: 'Teaching ADC League of Legends',
        game: 'League of Legends',
        level: 'Advanced',
        price: 35,
    }
];

export default coaches;