let ar = {
    "Your Way To": "طريقك إلى",
    "Become a Pro": "الأحتراف",
    "Discover": "اكتشف",
    "Choose your game": "اختر لعبتك",
    "Games": "الألعاب",
    "Coaches": "المدربين",
    "Reviews": "التقييمات",
    "Courses": "الدورات",
    "Dashboard": "لوحة التحكم",
    "Library": "المكتبة",
    "Log In": "تسجيل الدخول",
    "How it works": "كيف يعمل",
    "FAQ": "الأسئلة الشائعة",
    "Thousands of users are using EL GAMING ACADEMY Everyday!": "الآلاف من المستخدمين يستخدمون EL GAMING ACADEMY يوميا!",
    "Learn how it works": "تعلم كيف يعمل",
    "Choose your coach": "اختر مدربك",
    "See All": "عرض الكل",
    "Choose your course": "اختر دورتك",
    "Become a coach": "اصبح مدربا",
    "Currently logged out View":"تشاهد الآن كزائر",
    "Currently logged in View":"تشاهد الآن كمستخدم",
    "Switch to Logged in view?":"تغيير إلى المشاهدة كزائر؟",
    "Switch to Logged out view?":"تغيير إلى المشاهدة كمستخدم؟",
    "Our elite coaches are in the top 1% and can help you take your play to the next level.":"مدربينا المتميزين في النسبة الأولى 1% ويمكنهم مساعدتك على تحقيق أعلى مستوى للعبتك.",
    "Start Now":"ابدأ الآن",
    "New":"جديد",
    "Popular":"شائع",
    "Top Rated":"الأعلى تقييما",
    "Free":"مجاني",
    "Paid":"مدفوع",
    "All":"الكل",
    "Featured":"مميز",
    "Newest":"الأحدث",
    "Rising":"الأكثر ارتفاعا",
    "Starting at":"يبدأ من",
    "Show more courses":"عرض المزيد من الدورات",
    "Show more coaches":"عرض المزيد من المدربين",
    "Show less":"عرض أقل",
    "intermediate":"متوسط",
    "advanced":"متقدم",
    "Beginner":"مبتدئ",
    "Read User Reviews":"تقييمات المستخدمين",
    "Recommended Courses":"الدورات الموصى بها",
    "hrs":"ساعات",

};

export default ar;